import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { validateUserPermissions } from "../utils/validateUserPermissions";

import { NavHeader } from "components/NavHeader";
interface IPrivateRoute {
	permissions?: string[];
	roles?: string[];
	redirectTo?: string;
	children: ReactNode;
}

export function PrivateRoute({
	permissions,
	roles,
	redirectTo = "/login",
	children,
}: IPrivateRoute) {
	const { isAuthenticated, user } = useContext(AuthContext);

	const { hasAllPermissions } = validateUserPermissions({
		user,
		permissions,
		roles,
	});

	if (!isAuthenticated) {
		return <Navigate to={redirectTo} />;
	}

	if (!hasAllPermissions) {
		return <Navigate to="/" />;
	}

	return (
		<>
			<NavHeader />
			<div>{children}</div>
		</>
	);
}
