// seconds * minutes * hours * days
export const COOKIE_EXPIRATION_TIME = 60 * 60 * 24 // 1 day
export const TOKEN_COOKIE = 'hughesnet.token'
export const REFRESH_TOKEN_COOKIE = 'hughesnet.refreshToken'
export const BADGES_STORAGE_KEY = 'hughesnet.badges'

export const DATE_LAST_WEEK_START = 'GMT-8|-1w-|+1d';
export const DATE_WEEK_START = 'GMT-8|-0w-|+1d';
export const DATE_WEEK_END = 'GMT-8|-0w+|+1d';
export const DATE_DAY_START = 'GMT-8|-0d-';
export const DATE_DAY_END = 'GMT-8|-0d+';
export const DATE_14DAYS_AGO = 'GMT-8|-14d-';
export const DATE_TIMEZONE = '-0400';
