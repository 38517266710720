import './styles.scss';

export type HeadingSides = 'left' | 'right';
export type HeadingParams = {
  right?: boolean;
  children: any;
};

export default function Heading(props: HeadingParams) {
  const { right, children } = props;

  return (
    <div className={`heading relative ${right ? 'right' : ''}`}>
      <h1 className={`text-white font-bold xl:text-xl 2xl:text-3xl py-t ${right ? 'pr-32' : 'pl-32'}`}>{children}</h1>
    </div>
  );
}
