import { useContext, createContext, ReactNode, useEffect, useState, useCallback } from 'react';

interface ChildrenProp {
  children: ReactNode;
}

export interface NotificationContextInterface {
  showNotification : (type:string) => void,
  hideNotification : () => void,
  currentNotification? : string,
}

const NotificationContext = createContext({} as NotificationContextInterface);

function NotificationProviderData(): NotificationContextInterface {
  
  const [currentNotification, setCurrentNotification] = useState<string>();

  useEffect(() => {}, []);

  const showNotification = useCallback((type:string)=>{
    setCurrentNotification(type);
  },[]);

  function hideNotification(){
    setCurrentNotification(undefined);
  }

  return {
    showNotification,
    hideNotification,
    currentNotification
  };
}

const NotificationProvider = ({ children }: ChildrenProp) => {
  const contextData: NotificationContextInterface = NotificationProviderData();
  return <NotificationContext.Provider value={contextData}>{children}</NotificationContext.Provider>;
};

const useNotification = (): NotificationContextInterface => {
  const context = useContext(NotificationContext);

  if (typeof context === 'undefined') {
    throw new Error('useNotification must be used within an NotificationProvider');
  }

  return context;
};

export { NotificationProvider, useNotification };
