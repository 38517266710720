import { useState, useRef, useContext, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { BiUser, BiRefresh, BiLogOut } from 'react-icons/bi';

import AvatarEditor from 'components/AvatarEditor';
import { AuthContext } from 'context/AuthContext';
import { useBasic } from 'context/BasicContext';
import {
  uploadPicture,
  updateUserProfile,
  fetchDataURL,
  resizeImage,
} from 'services/profile.service';

import HexUser from 'assets/img/user-bar.svg';

export default function UserInfo() {
  const { pointsCategories } = useBasic();
  const { user, refreshUser, signOut } = useContext(AuthContext);

  const [avatarLoading, setAvatarLoading] = useState(false);
  const avatarEditor = useRef<any>();

  const getUserPointsObj = useCallback(() => {
    return Object.keys(user?.point_categories || {}).length && pointsCategories
      ? Object.keys(user?.point_categories).map((key) => {
          const point = pointsCategories?.find((item) => item._id === key);

          return {
            ...point,
            quantity: user?.point_categories[key],
          };
        })
      : null;
  }, [pointsCategories, user?.point_categories]);

  const onOpenAvatarEditor = () => {
    if (avatarLoading) return;
    avatarEditor.current?.open();
  };

  async function updateAvatarImage(dataURI: string) {
    try {
      const { uploads } = await uploadPicture(dataURI);
      const [{ url }] = uploads;
      if (url) {
        const url = uploads[0].url;
        const payload = {
          _id: user?._id,
          image: {
            small: { url },
            medium: { url },
            original: { url },
          },
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const updatedUser = await updateUserProfile(payload);
        refreshUser();

        // console.log(updatedUser);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function prepareToUpdateAvatar(url: string) {
    if (!user) return;

    setAvatarLoading(true);

    const imgResult = await fetchDataURL(url);
    if (imgResult) {
      const imgResized = await resizeImage(imgResult);
      await updateAvatarImage(imgResized);
    }
  }

  return (
    <>
      <div className="user-info relative mr-2.5">
        <div className="absolute w-full h-full p-8 flex items-center justify-between space-x-3">
          <div className="avatar w-16 h-16 bg-gray-100 rounded-full">
            <button
              onClick={onOpenAvatarEditor}
              className="w-full h-full flex items-center justify-center"
            >
              {!avatarLoading && !!user?.image?.medium?.url && (
                <img
                  id="avatar"
                  src={user?.image.medium.url}
                  alt=""
                  className="w-full h-full rounded-full overflow-hidden"
                />
              )}
              {!avatarLoading && !user?.image?.medium?.url && (
                <BiUser size={32} />
              )}
              {avatarLoading && (
                <span>
                  <BiRefresh size={32} className="animate-spin" />
                </span>
              )}
            </button>
          </div>
          <div className="flex-1">
            <h2 className="text-xl font-bold leading-none">{user?.name} </h2>
            <p className="text-blue-500 text-base italic font-normal">
              # {user?._id}
            </p>

            {!!getUserPointsObj() && (
              <div className="points">
                {getUserPointsObj()?.map((point) => (
                  <p
                    key={`${point._id}_${point.quantity}`}
                    data-tip={point.category}
                  >
                    <strong>{point.quantity}</strong>{' '}
                    <span>{point.category?.toLowerCase()}</span>
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className="flex space-x-3">
            {/* <button type="button" className="text-indigo-400">
      <FaCog size={44} />
    </button> */}
            <button
              onClick={() => signOut()}
              type="button"
              className="text-red-600 opacity-50"
            >
              <BiLogOut size={44} />
            </button>
          </div>
        </div>
        <img src={HexUser} alt="" />
      </div>
      <AvatarEditor
        ref={avatarEditor}
        onRenderStart={() => {
          setAvatarLoading(true);
        }}
        onRenderFinished={(url) => {
          prepareToUpdateAvatar(url);
        }}
      />
      <ReactTooltip type="info" effect="solid" className="tooltip" />
    </>
  );
}
