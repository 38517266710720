import {
  DATE_WEEK_START,
  DATE_WEEK_END,
  DATE_DAY_START,
  DATE_DAY_END,
  DATE_LAST_WEEK_START,
  DATE_TIMEZONE,
  DATE_14DAYS_AGO,
} from 'utils/constants';
import { api } from './api';

export async function getLastAction(player: string) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: {
            $in: ['rollingStats', 'addCall'],
          },
          userId: player,
        },
      },
      {
        $sort: {
          time: -1,
        },
      },
      {
        $limit: 1,
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data[0];
    }
  } catch (err) {
    return;
  }
}

export async function getRollingStats(player: string) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'rollingStats',
          userId: player,
        },
      },
      {
        $sort: {
          time: -1,
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data[0];
    }
  } catch (err) {
    return;
  }
}

export async function getWeeklyStats(
  player: string,
  startDate?: number,
  endDate?: number
) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'addCall',
          userId: player,
          time: {
            $gte: {
              $date:
                typeof startDate === 'number' ? startDate : DATE_WEEK_START,
            },
            $lte: {
              $date: typeof endDate === 'number' ? endDate : DATE_WEEK_END,
            },
          },
        },
      },
      {
        $group: {
          _id: null,
          us_consumer_call: {
            $sum: '$attributes.us_consumer_call',
          },
          us_consumer_order: {
            $sum: '$attributes.us_consumer_order',
          },
          dealer_call: {
            $sum: '$attributes.dealer_call',
          },
          dealer_order: {
            $sum: '$attributes.dealer_order',
          },
          referral_call: {
            $sum: '$attributes.referral_call',
          },
          referral_order: {
            $sum: '$attributes.referral_order',
          },
          business_call: {
            $sum: '$attributes.business_call',
          },
          business_order: {
            $sum: '$attributes.business_order',
          },
          consumer_spanish_call: {
            $sum: '$attributes.consumer_spanish_call',
          },
          consumer_spanish_order: {
            $sum: '$attributes.consumer_spanish_order',
          },
          pr_call: {
            $sum: '$attributes.pr_call',
          },
          pr_order: {
            $sum: '$attributes.pr_order',
          },
          mx_call: {
            $sum: '$attributes.mx_call',
          },
          mx_order: {
            $sum: '$attributes.mx_order',
          },
          consumer_er_basic: {
            $sum: '$attributes.consumer_er_basic',
          },
          consumer_er_premium: {
            $sum: '$attributes.consumer_er_premium',
          },
          us_consumer_er_basic: {
            $sum: '$attributes.us_consumer_er_basic',
          },
          us_consumer_er_premium: {
            $sum: '$attributes.us_consumer_er_premium',
          },
          consumer_flex_offer: {
            $sum: '$attributes.consumer_flex_offer',
          },
          us_consumer_flex_offer: {
            $sum: '$attributes.us_consumer_flex_offer',
          },
          consumer_norton: {
            $sum: '$attributes.consumer_norton',
          },
          us_consumer_norton: {
            $sum: '$attributes.us_consumer_norton',
          },
          business_er_basic: {
            $sum: '$attributes.business_er_basic',
          },
          business_er_premium: {
            $sum: '$attributes.business_er_premium',
          },
          business_flex_offer: {
            $sum: '$attributes.business_flex_offer',
          },
          business_norton: {
            $sum: '$attributes.business_norton',
          },
          consumer_voice_domestic_2yr_commit: {
            $sum: '$attributes.consumer_voice_domestic_2yr_commit',
          },
          consumer_voice_domestic_no_commit: {
            $sum: '$attributes.consumer_voice_domestic_no_commit',
          },
          business_voice_domestic_2yr_commit: {
            $sum: '$attributes.business_voice_domestic_2yr_commit',
          },
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data;
    }
  } catch (err) {
    return;
  }
}

export async function getCurrentStats(
  player: string,
  startDate?: number,
  endDate?: number
) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'addCall',
          userId: player,
          time: {
            $gte: {
              $date: typeof startDate === 'number' ? startDate : DATE_DAY_START,
            },
            $lte: {
              $date: typeof endDate === 'number' ? endDate : DATE_DAY_END,
            },
          },
        },
      },
      {
        $group: {
          _id: null,
          us_consumer_call: {
            $sum: '$attributes.us_consumer_call',
          },
          us_consumer_order: {
            $sum: '$attributes.us_consumer_order',
          },
          dealer_call: {
            $sum: '$attributes.dealer_call',
          },
          dealer_order: {
            $sum: '$attributes.dealer_order',
          },
          referral_call: {
            $sum: '$attributes.referral_call',
          },
          referral_order: {
            $sum: '$attributes.referral_order',
          },
          business_call: {
            $sum: '$attributes.business_call',
          },
          business_order: {
            $sum: '$attributes.business_order',
          },
          consumer_spanish_call: {
            $sum: '$attributes.consumer_spanish_call',
          },
          consumer_spanish_order: {
            $sum: '$attributes.consumer_spanish_order',
          },
          pr_call: {
            $sum: '$attributes.pr_call',
          },
          pr_order: {
            $sum: '$attributes.pr_order',
          },
          mx_call: {
            $sum: '$attributes.mx_call',
          },
          mx_order: {
            $sum: '$attributes.mx_order',
          },
          consumer_er_basic: {
            $sum: '$attributes.consumer_er_basic',
          },
          consumer_er_premium: {
            $sum: '$attributes.consumer_er_premium',
          },
          us_consumer_er_basic: {
            $sum: '$attributes.us_consumer_er_basic',
          },
          us_consumer_er_premium: {
            $sum: '$attributes.us_consumer_er_premium',
          },
          consumer_flex_offer: {
            $sum: '$attributes.consumer_flex_offer',
          },
          us_consumer_flex_offer: {
            $sum: '$attributes.us_consumer_flex_offer',
          },
          consumer_norton: {
            $sum: '$attributes.consumer_norton',
          },
          us_consumer_norton: {
            $sum: '$attributes.us_consumer_norton',
          },
          business_er_basic: {
            $sum: '$attributes.business_er_basic',
          },
          business_er_premium: {
            $sum: '$attributes.business_er_premium',
          },
          business_flex_offer: {
            $sum: '$attributes.business_flex_offer',
          },
          business_norton: {
            $sum: '$attributes.business_norton',
          },
          consumer_voice_domestic_2yr_commit: {
            $sum: '$attributes.consumer_voice_domestic_2yr_commit',
          },
          consumer_voice_domestic_no_commit: {
            $sum: '$attributes.consumer_voice_domestic_no_commit',
          },
          business_voice_domestic_2yr_commit: {
            $sum: '$attributes.business_voice_domestic_2yr_commit',
          },
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data;
    }
  } catch (err) {
    return;
  }
}

// Get daily stats from last week and current week
export async function getDailyStats(
  player: string,
  startDate?: number,
  endDate?: number
) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'addCall',
          userId: player,
          time: {
            $gte: {
              $date:
                typeof startDate === 'number'
                  ? startDate
                  : DATE_LAST_WEEK_START,
            },
            $lte: {
              $date: typeof endDate === 'number' ? endDate : DATE_WEEK_END,
            },
          },
        },
      },
      {
        $addFields: {
          yearMonthDay: {
            $dateToString: {
              format: '%Y-%m-%d',
              date: '$time',
              timezone: DATE_TIMEZONE,
            },
          },
          dayOfWeek: {
            $dayOfWeek: {
              date: '$time',
              timezone: DATE_TIMEZONE,
            },
          },
        },
      },
      {
        $group: {
          _id: '$yearMonthDay',
          day_of_week: { $first: '$dayOfWeek' },
          us_consumer_call: {
            $sum: '$attributes.us_consumer_call',
          },
          us_consumer_order: {
            $sum: '$attributes.us_consumer_order',
          },
          dealer_call: {
            $sum: '$attributes.dealer_call',
          },
          dealer_order: {
            $sum: '$attributes.dealer_order',
          },
          referral_call: {
            $sum: '$attributes.referral_call',
          },
          referral_order: {
            $sum: '$attributes.referral_order',
          },
          business_call: {
            $sum: '$attributes.business_call',
          },
          business_order: {
            $sum: '$attributes.business_order',
          },
          consumer_spanish_call: {
            $sum: '$attributes.consumer_spanish_call',
          },
          consumer_spanish_order: {
            $sum: '$attributes.consumer_spanish_order',
          },
          pr_call: {
            $sum: '$attributes.pr_call',
          },
          pr_order: {
            $sum: '$attributes.pr_order',
          },
          mx_call: {
            $sum: '$attributes.mx_call',
          },
          mx_order: {
            $sum: '$attributes.mx_order',
          },
          consumer_er_basic: {
            $sum: '$attributes.consumer_er_basic',
          },
          consumer_er_premium: {
            $sum: '$attributes.consumer_er_premium',
          },
          us_consumer_er_basic: {
            $sum: '$attributes.us_consumer_er_basic',
          },
          us_consumer_er_premium: {
            $sum: '$attributes.us_consumer_er_premium',
          },
          consumer_flex_offer: {
            $sum: '$attributes.consumer_flex_offer',
          },
          us_consumer_flex_offer: {
            $sum: '$attributes.us_consumer_flex_offer',
          },
          consumer_norton: {
            $sum: '$attributes.consumer_norton',
          },
          us_consumer_norton: {
            $sum: '$attributes.us_consumer_norton',
          },
          business_er_basic: {
            $sum: '$attributes.business_er_basic',
          },
          business_er_premium: {
            $sum: '$attributes.business_er_premium',
          },
          business_flex_offer: {
            $sum: '$attributes.business_flex_offer',
          },
          business_norton: {
            $sum: '$attributes.business_norton',
          },
          consumer_voice_domestic_2yr_commit: {
            $sum: '$attributes.consumer_voice_domestic_2yr_commit',
          },
          consumer_voice_domestic_no_commit: {
            $sum: '$attributes.consumer_voice_domestic_no_commit',
          },
          business_voice_domestic_2yr_commit: {
            $sum: '$attributes.business_voice_domestic_2yr_commit',
          },
        },
      },
      {
        $sort: {
          _id: 1,
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data;
    }
  } catch (err) {
    return;
  }
}

export async function getPersonalBestStats(
  player: string,
  startDate?: number,
  endDate?: number
) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'addCall',
          userId: player,
          time: {
            $gte: {
              $date:
                typeof startDate === 'number' ? startDate : DATE_14DAYS_AGO,
            },
            $lte: {
              $date: typeof endDate === 'number' ? endDate : 'NOW',
            },
          },
        },
      },
      {
        $addFields: {
          yearMonthDay: {
            $dateToString: {
              format: '%Y-%m-%d',
              date: '$time',
              timezone: DATE_TIMEZONE,
            },
          },
        },
      },
      {
        $group: {
          _id: '$yearMonthDay',
          us_consumer_call: {
            $sum: '$attributes.us_consumer_call',
          },
          us_consumer_order: {
            $sum: '$attributes.us_consumer_order',
          },
          dealer_call: {
            $sum: '$attributes.dealer_call',
          },
          dealer_order: {
            $sum: '$attributes.dealer_order',
          },
          referral_call: {
            $sum: '$attributes.referral_call',
          },
          referral_order: {
            $sum: '$attributes.referral_order',
          },
          business_call: {
            $sum: '$attributes.business_call',
          },
          business_order: {
            $sum: '$attributes.business_order',
          },
          consumer_spanish_call: {
            $sum: '$attributes.consumer_spanish_call',
          },
          consumer_spanish_order: {
            $sum: '$attributes.consumer_spanish_order',
          },
          pr_call: {
            $sum: '$attributes.pr_call',
          },
          pr_order: {
            $sum: '$attributes.pr_order',
          },
          mx_call: {
            $sum: '$attributes.mx_call',
          },
          mx_order: {
            $sum: '$attributes.mx_order',
          },
          consumer_er_basic: {
            $sum: '$attributes.consumer_er_basic',
          },
          consumer_er_premium: {
            $sum: '$attributes.consumer_er_premium',
          },
          consumer_flex_offer: {
            $sum: '$attributes.consumer_flex_offer',
          },
          consumer_norton: {
            $sum: '$attributes.consumer_norton',
          },
          business_er_basic: {
            $sum: '$attributes.business_er_basic',
          },
          business_er_premium: {
            $sum: '$attributes.business_er_premium',
          },
          business_flex_offer: {
            $sum: '$attributes.business_flex_offer',
          },
          business_norton: {
            $sum: '$attributes.business_norton',
          },
          pr_flex_offer: {
            $sum: '$attributes.pr_flex_offer',
          },
          consumer_voice_domestic_2yr_commit: {
            $sum: '$attributes.consumer_voice_domestic_2yr_commit',
          },
          consumer_voice_domestic_no_commit: {
            $sum: '$attributes.consumer_voice_domestic_no_commit',
          },
          business_voice_domestic_2yr_commit: {
            $sum: '$attributes.business_voice_domestic_2yr_commit',
          },
        },
      },
      {
        $project: {
          _id: 1,
          us_consumer: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$us_consumer_order', 0],
                  },
                  {
                    $eq: ['$us_consumer_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$us_consumer_order', '$us_consumer_call'],
              },
            ],
          },
          dealer: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$dealer_order', 0],
                  },
                  {
                    $eq: ['$dealer_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$dealer_order', '$dealer_call'],
              },
            ],
          },
          referral: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$referral_order', 0],
                  },
                  {
                    $eq: ['$referral_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$referral_order', '$referral_call'],
              },
            ],
          },
          business: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$business_order', 0],
                  },
                  {
                    $eq: ['$business_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$business_order', '$business_call'],
              },
            ],
          },
          consumer_spanish: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$consumer_spanish_order', 0],
                  },
                  {
                    $eq: ['$consumer_spanish_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$consumer_spanish_order', '$consumer_spanish_call'],
              },
            ],
          },
          pr: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$pr_order', 0],
                  },
                  {
                    $eq: ['$pr_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$pr_order', '$pr_call'],
              },
            ],
          },
          mx: {
            $cond: [
              {
                $or: [
                  {
                    $eq: ['$mx_order', 0],
                  },
                  {
                    $eq: ['$mx_call', 0],
                  },
                ],
              },
              0,
              {
                $divide: ['$mx_order', '$mx_call'],
              },
            ],
          },
        },
      },
      {
        $group: {
          _id: null,
          us_consumer: {
            $max: '$us_consumer',
          },
          dealer: {
            $max: '$dealer',
          },
          referral: {
            $max: '$referral',
          },
          business: {
            $max: '$business',
          },
          consumer_spanish: {
            $max: '$consumer_spanish',
          },
          pr: {
            $max: '$pr',
          },
          mx: {
            $max: '$mx',
          },
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data.length > 0 ? data[0] : [];
    }
  } catch (err) {
    return;
  }
}

export async function getAverageStats(startDate?: number, endDate?: number) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'addCall',
          time: {
            $gte: {
              $date:
                typeof startDate === 'number' ? startDate : DATE_14DAYS_AGO,
            },
            $lte: {
              $date: typeof endDate === 'number' ? endDate : DATE_DAY_END,
            },
          },
        },
      },
      {
        $group: {
          _id: null,
          us_consumer_call: {
            $avg: '$attributes.us_consumer_call',
          },
          us_consumer_order: {
            $avg: '$attributes.us_consumer_order',
          },
          dealer_call: {
            $avg: '$attributes.dealer_call',
          },
          dealer_order: {
            $avg: '$attributes.dealer_order',
          },
          referral_call: {
            $avg: '$attributes.referral_call',
          },
          referral_order: {
            $avg: '$attributes.referral_order',
          },
          business_call: {
            $avg: '$attributes.business_call',
          },
          business_order: {
            $avg: '$attributes.business_order',
          },
          consumer_spanish_call: {
            $avg: '$attributes.consumer_spanish_call',
          },
          consumer_spanish_order: {
            $avg: '$attributes.consumer_spanish_order',
          },
          pr_call: {
            $avg: '$attributes.pr_call',
          },
          pr_order: {
            $avg: '$attributes.pr_order',
          },
          mx_call: {
            $avg: '$attributes.mx_call',
          },
          mx_order: {
            $avg: '$attributes.mx_order',
          },
          consumer_er_basic: {
            $avg: '$attributes.consumer_er_basic',
          },
          consumer_er_premium: {
            $avg: '$attributes.consumer_er_premium',
          },
          us_consumer_er_basic: {
            $avg: '$attributes.us_consumer_er_basic',
          },
          us_consumer_er_premium: {
            $avg: '$attributes.us_consumer_er_premium',
          },
          us_consumer_flex_offer: {
            $avg: '$attributes.us_consumer_flex_offer',
          },
          us_consumer_norton: {
            $avg: '$attributes.us_consumer_norton',
          },
          consumer_flex_offer: {
            $avg: '$attributes.consumer_flex_offer',
          },
          consumer_norton: {
            $avg: '$attributes.consumer_norton',
          },
          business_er_basic: {
            $avg: '$attributes.business_er_basic',
          },
          business_er_premium: {
            $avg: '$attributes.business_er_premium',
          },
          business_flex_offer: {
            $avg: '$attributes.business_flex_offer',
          },
          business_norton: {
            $avg: '$attributes.business_norton',
          },
          pr_flex_offer: {
            $avg: '$attributes.pr_flex_offer',
          },
          consumer_voice_domestic_2yr_commit: {
            $avg: '$attributes.consumer_voice_domestic_2yr_commit',
          },
          consumer_voice_domestic_no_commit: {
            $avg: '$attributes.consumer_voice_domestic_no_commit',
          },
          business_voice_domestic_2yr_commit: {
            $avg: '$attributes.business_voice_domestic_2yr_commit',
          },
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data[0];
    }
  } catch (err) {
    return;
  }
}

export async function getTotalUsers(startDate?: number, endDate?: number) {
  try {
    const { data } = await api.post('database/action_log/aggregate', [
      {
        $match: {
          actionId: 'addCall',
          time: {
            $gte: {
              $date:
                typeof startDate === 'number' ? startDate : DATE_14DAYS_AGO,
            },
            $lte: {
              $date: typeof endDate === 'number' ? endDate : DATE_DAY_END,
            },
          },
        },
      },
      {
        $group: {
          _id: '$userId',
        },
      },
      {
        $group: {
          _id: null,
          total: {
            $sum: 1,
          },
        },
      },
      {
        $project: {
          _id: 0,
          total: 1,
        },
      },
    ]);

    if (!Array.isArray(data)) {
      return;
    } else {
      return data[0];
    }
  } catch (err) {
    return;
  }
}
