import { DATE_WEEK_END, DATE_WEEK_START } from 'utils/constants';
import { api } from './api';

export interface BoardsInterface {
  title: string;
  principalType: number;
  operation: BoardsOperation;
  period: BoardsPeriod;
  techniques: string[];
  _id: string;
}

interface BoardsPeriod {
  type: number;
  timeAmount: number;
  timeScale: number;
}

interface BoardsOperation {
  type: number;
  achievement_type: number;
  sort: number;
  sub: boolean;
  prepared: string;
}

export interface LeaderboardInterface {
  leaderboard: LeaderboardInfo;
  start_datetime: string;
  end_datetime: string;
  creation_datetime: string;
  expiration_datetime: string;
  leaders: LeaderboardLeaders[];
  my_position?: LeaderboardLeaders;
}

interface LeaderboardLeaders {
  boardId: string;
  player: string;
  name: string;
  position: number;
  total: number;
  previous_position: number;
  previous_total: number;
  move: string;
  extra: any;
  _id: string;
  image?: string;
}

interface LeaderboardInfo {
  title: string;
  principalType: number;
  operation: LeaderboardInfoOperation;
  period: LeaderboardInfoPeriod;
  techniques: string[];
  _id: string;
}

interface LeaderboardInfoPeriod {
  type: number;
  timeAmount: number;
  timeScale: number;
  startDate: number;
  endDate: number;
}

interface LeaderboardInfoOperation {
  type: number;
  achievement_type: number;
  sort: number;
  sub: boolean;
  prepared: string;
}

export async function getLeaderboards(): Promise<BoardsInterface[]> {
  try {
    const { data } = await api.get(`leaderboard`);

    if (!Array.isArray(data)) {
      let message = '';
      if (data.errorMessage) message = data.errorMessage;
      throw new Error(message);
    } else {
      return data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getLeaderboard(
  leaderboardId: string,
  userId: string,
  maxResults: number = -1,
  dates: string = `start_date=${encodeURIComponent(DATE_WEEK_START)}&end_date=${encodeURIComponent(DATE_WEEK_END)}`,
  viewType: number = 1
): Promise<LeaderboardInterface> {
  const { data } = await api.get(`leaderboard/${leaderboardId}/leaders?player=${userId}&max_results=${maxResults}&${dates}&view=${viewType}&time_scale=1&live=true`);

  return data;
}

export async function getPlayerPosition(leaderboardId: string, userId: string, cache?: string): Promise<LeaderboardLeaders> {
  if (!leaderboardId || !userId) throw new Error('"leaderboardId" and "userId" parameters are required.');

  try {
    const payload: any = {
      $match: {
        boardId: leaderboardId,
        player: userId,
      },
    };

    if (typeof cache === 'string') {
      payload.$match['extra.cache'] = cache;
    }

    const { data } = await api.post(`database/leader/aggregate`, [{ ...payload }]);

    if (!Array.isArray(data)) {
      let message = '';
      if (data.errorMessage) message = data.errorMessage;
      throw new Error(message);
    } else {
      return data.length ? data[0] : undefined;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
