/* eslint-disable @typescript-eslint/no-unused-vars */
import CardBody from 'assets/img/card-body.svg';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { DailyChart } from './chart';

type DailyCardParams = {
  data?: any;
  type?: string;
  label: string;
  responsive?: boolean;
  actualDates: {
    defaultStartDate: Date;
    defaultEndDate: Date;
    startDate: Date;
    endDate: Date;
  };
};
export default function DailyCard(props: DailyCardParams) {
  const { label, type, data, responsive, actualDates } = props;

  const [orders, setOrders] = useState(0);
  const [calls, setCalls] = useState(0);

  function closeRate() {
    if (!orders || !calls) return 0;
    return Math.round((orders / calls) * 100);
  }

  function getPersonalBest() {
    try {
      if (data && type && data.personalBest[type]) {
        return (data.personalBest[type] * 100).toFixed(0);
      }
      return 0;
    } catch (err) {
      return 0;
    }
  }

  function getAverageBest() {
    try {
      const best = data && data.average && data.average[`${type}_order`] ? data.average[`${type}_order`] : 0;
      return best.toFixed(2);
    } catch (err) {
      return;
    }
  }

  function getDailyActivity() {
    try {
      const result = data.dailyActivity.map((item: any) => {
        const dailyCalls = item[`${type}_call`] || 0;
        const dailyOrders = item[`${type}_order`] || 0;

        let dailyPerformance = 0;

        if (dailyCalls !== 0 && dailyOrders !== 0) {
          dailyPerformance = (dailyOrders / dailyCalls) * 100;
        }

        return {
          date: new Date(item._id),
          performance: dailyPerformance,
        };
      });

      return result;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  useEffect(() => {
    try {
      if (data) {
        // set calls for the current day
        setCalls(data.activity[0][`${type}_call`]);
        setOrders(data.activity[0][`${type}_order`]);

        // console.log("Activity", data.activity,data.dailyActivity,data.personalBest);

        const best = data.personalBest[`${type}_order`] || 0;

        // data.dailyActivity.forEach((item:any) => {
        //   if(item){
        //     // console.log(type, item[`${type}_order`], data.personalBest[`${type}_order`]);
        //   }
        // });
      }
    } catch (e) {
      //
    }
  }, [data, type]);

  const getRangeText = useMemo(() => {
    if (actualDates.defaultEndDate === actualDates.endDate) {
      const betweenDates = moment(actualDates.endDate).diff(moment(actualDates.startDate), 'days');

      return `Past ${betweenDates} day${betweenDates > 1 ? 's' : ''}`;
    } else {
      return 'Custom period';
    }
    // defaultStartDate: defaultStartDate,
    // defaultEndDate: defaultEndDate,
    // startDate: startDate,
    // endDate: endDate,
  }, [actualDates]);

  return (
    <div className="daily-card bg-white p-4 rounded-xl">
      <div className="flex justify-between">
        <div>
          <p className="text-sm">Orders / Calls</p>
          <p className="text-left text-2xl text-blue-500">
            <b>{orders}</b>/{calls}
          </p>
        </div>
        <div className="text-right">
          <p className="text-sm">Close Rate</p>
          <p className="font-bold text-2xl">{closeRate()}%</p>
        </div>
      </div>
      <div className={`my-1 mb-2 ${!responsive ? '' : ''}`}>
        <DailyChart activity={getDailyActivity()} personal={getPersonalBest()} average={getAverageBest() * 100} />
      </div>
      <div className="text-center text-sm font-bold flex flex-col leading-none mb-1">{getRangeText}</div>
      <div className="flex justify-between text-center leading-none text-sm">
        <div className="text-red-500">
          <p className="text-xs">Personal Best</p>
          <p>
            <b>{getPersonalBest()}%</b>
          </p>
        </div>
        <div className="text-purple-500">
          <p className="text-xs">Agents Average</p>
          <p>
            <b>{getAverageBest() * 100}%</b>
          </p>
        </div>
      </div>
      <div className="text-xl mt-2">{label}</div>
    </div>
  );
}
