export default function HexUp({color="#88D824"}) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 110 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7_669)">
        <path
          d="M54.9466 91.2748L11.8926 90.8124C6.41196 90.7535 1.99999 86.2939 1.99999 80.813L1.99999 35.3422C1.99999 31.7695 3.90598 28.4683 6.99999 26.6819L49.9466 1.88674C53.0406 0.100413 56.8526 0.100414 59.9466 1.88674L102.893 26.682C105.987 28.4683 107.893 31.7696 107.893 35.3422L107.893 80.813C107.893 86.2939 103.481 90.7535 98.0005 90.8124L54.9466 91.2748Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7_669"
          x="0"
          y="0.546997"
          width="109.893"
          height="94.7278"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_669" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_669" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
