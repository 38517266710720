/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';

import { AuthContext } from 'context/AuthContext';

import { DashboardFull } from './Full';
import { DashboardUS } from './US';
import { DashboardMX } from './MX';

export function Dashboard() {
  const { user } = useContext(AuthContext);
  
  if(!user) return null;

  return (<>
    {user.extra?.dashboard === 'full' && <DashboardFull />}
    {user.extra?.dashboard === 'us' && <DashboardUS />}
    {user.extra?.dashboard === 'mx' && <DashboardMX />}
  </>)
}
