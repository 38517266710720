import { HexStatusType } from 'components/Hex';

export function getHexStatus(value: number, key?: string): HexStatusType {
  if (!isFinite(value)) {
    return 'low';
  }

  let status: HexStatusType = 'medium';

  if (!key) {
    if (value < 10) {
      return 'low';
    } else if (value > 50) {
      return 'high';
    }
  } else {
    switch (key) {
      // ROLLING STATS
      case 'consumer_activations':
      case 'business_activations':
      case 'mx_activations':
        if (value < 79) {
          status = 'low';
        } else if (value > 80) {
          status = 'high';
        }
        break;
      case 'mx_paid_orders':
        if (value < 69) {
          status = 'low';
        } else if (value > 80) {
          status = 'high';
        }
        break;
      case 'mx_credit_payments':
        if (value < 35) {
          status = 'low';
        } else if (value > 35) {
          status = 'high';
        }
        break;

      // CLOSING OFFERS
      case 'closing_offer_us':
      case 'closing_offer_business':
      case 'closing_offer_pr':
        if (value > 41) {
          status = 'low';
        } else if (value < 40) {
          status = 'high';
        }
        break;

      // OPTIONAL SERVICES
      // CONSUMER
      case 'consumer_voice_attachment':
        if (value > 10) {
          status = 'high';
        } else if (value < 9) {
          status = 'low';
        }
        break;
      case 'consumer_er_attachment':
        if (value > 55) {
          status = 'high';
        } else if (value < 54) {
          status = 'low';
        }
        break;
      case 'consumer_norton_attachment':
        if (value > 35) {
          status = 'high';
        } else if (value < 34) {
          status = 'low';
        }
        break;

      // BUSINESS
      case 'business_voice_attachment':
        if (value > 15) {
          status = 'high';
        } else if (value < 14) {
          status = 'low';
        }
        break;
      case 'business_er_attachment':
        if (value > 42) {
          status = 'high';
        } else if (value < 41) {
          status = 'low';
        }
        break;
      case 'business_norton_attachment':
        if (value > 35) {
          status = 'high';
        } else if (value < 34) {
          status = 'low';
        }
        break;

      // BUSINESS
      case 'pr_voice_attachment':
        if (value > 2) {
          status = 'high';
        } else if (value < 1) {
          status = 'low';
        }
        break;
      case 'pr_er_attachment':
        status = 'high';
        break;
      case 'pr_norton_attachment':
        if (value > 35) {
          status = 'high';
        } else if (value < 34) {
          status = 'low';
        }
        break;

      default:
        status = 'low';
        break;
    }
  }

  return status;
}
