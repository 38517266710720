export default function HexWide({ color = '#FF9B05' }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 239 115" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_37_1822)">
<path d="M233.075 48.1846C234.871 51.2848 234.871 55.1088 233.075 58.209L208.054 101.406C206.266 104.493 202.969 106.394 199.401 106.394L39.5774 106.394C36.0098 106.394 32.7124 104.493 30.9242 101.406L5.90323 58.209C4.1075 55.1088 4.1075 51.2848 5.90324 48.1846L30.9242 4.98779C32.7124 1.90066 36.0098 -4.34478e-06 39.5774 -4.28155e-06L199.401 -1.44905e-06C202.969 -1.38582e-06 206.266 1.90066 208.054 4.98779L233.075 48.1846Z" fill={color}/>
</g>
<defs>
<filter id="filter0_d_37_1822" x="0.556458" y="0" width="237.866" height="114.394" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37_1822"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37_1822" result="shape"/>
</filter>
</defs>
</svg>

  );
}
