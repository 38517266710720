import HexBox from 'components/HexBox';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DashboardFilterProps {
  onChangeDate: (startDate: Date, endDate: Date) => void;
  defaultStartDate: Date;
  defaultEndDate: Date;
}
export function DashboardFilter({ onChangeDate, defaultStartDate, defaultEndDate }: DashboardFilterProps) {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleChange = useCallback(() => {
    if (startDate < endDate) {
      onChangeDate(startDate, endDate);
    }
  }, [onChangeDate, startDate, endDate]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return (
    <div className="relative z-40">
      <HexBox>
        <div className="flex flex-row divide-x divide-gray-600">
          <div className="pr-4">
            <DatePicker className="min-w-auto w-24 text-center" selected={startDate} onChange={(date: Date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} />
          </div>
          <div className="pl-4">
            <DatePicker className="min-w-auto w-24 text-center" selected={endDate} onChange={(date: Date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} />
          </div>
        </div>
      </HexBox>
    </div>
  );
}
