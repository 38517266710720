/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import { Routes, Route } from "react-router-dom";

import { Dashboard } from "pages/Dashboard";
import { Login } from "pages/Login";
import { NotFound } from "pages/NotFound";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const RouteList = () => (
	<Routes>
		<Route
			path="/"
			element={
				<PrivateRoute redirectTo="/login">
					<Dashboard />
				</PrivateRoute>
			}
		/>

		<Route
			path="/login"
			element={
				<PublicRoute>
					<Login />
				</PublicRoute>
			}
		/>

		<Route path="*" element={<NotFound />} />
	</Routes>
);
