import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaAngleUp,
} from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';

import { AuthContext } from 'context/AuthContext';
import {
  getLeaderboard,
  LeaderboardInterface,
  getPlayerPosition,
} from 'services/leaderboard.service';
import ArticlePlaceholder from 'components/ui/article-placeholder';

import IconLeaderboard from 'assets/img/icon-leaderboard.png';

const LeaderboardsModal = React.forwardRef((props, ref) => {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  const [boards, setBoards] = useState<LeaderboardInterface[]>([]);
  const [activeBoardIndex, setActiveBoardIndex] = useState<number>();
  // const [activeLeaderboard, setActiveLeaderboard] =
  //   useState<LeaderboardInterface>();
  const [leaderboardsIDs, setLeaderboardsIDs] = useState<string[]>();
  const [requestCounter, setRequestCounter] = useState<number>(0);

  const [showModal, setShowModal] = useState(false);
  const [closing, setClosing] = useState(false);

  function open() {
    setShowModal(true);
  }

  function close() {
    setClosing(true);

    setTimeout(() => {
      setShowModal(false);
      setClosing(false);

      setBoards([]);
      setActiveBoardIndex(undefined);
      // setActiveLeaderboard(undefined);
    }, 300);
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
    setLeaderboardsIDs,
  }));

  const activeLeaderboard = useMemo(() => {
    return boards[activeBoardIndex || 0];
  }, [activeBoardIndex, boards]);

  const initialLoad = useCallback(() => {
    if (!user || !leaderboardsIDs) return;

    setLoading(true);
    setRequestCounter(0);

    const promises: any[] = [];

    leaderboardsIDs.forEach((id) => {
      promises.push(
        getLeaderboard(id, user._id, 5)
          .then(async (leaderboard) => {
            setRequestCounter((prevNumber) => prevNumber + 1);

            try {
              const myPosition = await getPlayerPosition(
                leaderboard.leaderboard._id,
                user._id,
                leaderboard.leaders[0]?.extra?.cache || undefined
              );

              setRequestCounter((prevNumber) => prevNumber + 1);

              return { ...leaderboard, my_position: myPosition || null };
            } catch {
              setRequestCounter((prevNumber) => prevNumber + 1);
              return null;
            }
          })
          .catch(() => {
            setRequestCounter((prevNumber) => prevNumber + 1);
            return null;
          })
      );
    });

    Promise.all(promises)
      .then((response) => {
        const _boards = response.filter(
          (x) => !!x && !!x.leaders
        );

        if (_boards?.length) {
          setBoards(_boards);
          setActiveBoardIndex(0);
        }
      })
      .catch(() => null)
      .finally(() => setLoading(false));
  }, [leaderboardsIDs, user]);

  const paginateIsInactive = (direction: 'prev' | 'next') => {
    if (!boards || typeof activeBoardIndex !== 'number' || boards.length <= 1)
      return true;

    if (direction === 'prev') {
      return activeBoardIndex === 0 ? true : false;
    } else {
      return activeBoardIndex + 1 === boards.length ? true : false;
    }
  };

  useEffect(() => {
    if (!user || !showModal || !leaderboardsIDs) return;

    initialLoad();
  }, [user, showModal, initialLoad, leaderboardsIDs]);

  function renderLoading() {
    return (
      <div className="relative h-full w-full">
        <p className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-1/2 text-4xl z-10 text-blue-600">
          {Math.round(
            (requestCounter * 100) / ((leaderboardsIDs?.length || 0) * 2)
          )}
          %
        </p>
        <ArticlePlaceholder />
      </div>
    );
  }

  function noResult() {
    return (
      <div>
        <p className="block not-italic text-xl font-medium text-center text-white">
          No results to show
        </p>
      </div>
    );
  }

  function renderList() {
    if (!boards || !boards.length) {
      return noResult();
    }

    const showMyPosition = () => {
      if (!activeLeaderboard?.my_position) return null;

      return (
        <div className="my-position flex items-center justify-between bg-white rounded-2xl w-full py-3 px-11 mb-5 text-gray-900">
          <p className="position">
            <span>Rank</span>
            {activeLeaderboard.my_position.position}
          </p>
          <div className="user flex flex-col items-center justify-center">
            <span
              className={`img ${
                !!user?.image?.medium?.url ? 'has-image' : 'no-image'
              } w-16 h-16 flex items-center justify-center rounded-full overflow-hidden border border-white border-solid mb-1.5`}
            >
              {!!user?.image?.medium?.url ? (
                <>
                  <img
                    src={user?.image?.medium?.url}
                    alt={user?.name}
                    className="h-full w-full block object-cover"
                  />
                </>
              ) : (
                <>
                  <BiUser className="w-full h-full bg-gray-100" />
                </>
              )}
            </span>
            <p>{user?.name}</p>
          </div>

          <p className="rate">
            <span>Rate</span>
            {activeLeaderboard.my_position.total}
          </p>
        </div>
      );
    };

    return (
      <div className="boards flex flex-col pl-32 pr-28 py-0 w-full h-full">
        <div className="head flex items-start justify-between text-white pb-6 w-full pr-4">
          <button
            onClick={() =>
              paginateIsInactive('prev')
                ? null
                : setActiveBoardIndex((prev) =>
                    typeof prev === 'number' ? prev - 1 : 0
                  )
            }
            disabled={paginateIsInactive('prev')}
            className={`btn-prev text-xl leading-none mt-1.5 ${
              paginateIsInactive('prev')
                ? 'opacity-0 pointer-events-none'
                : 'cursor-pointer'
            }`}
          >
            <FaAngleLeft />
          </button>
          <div className="board-title flex flex-col items-center justify-start">
            <h4 className="not-italic text-2xl font-bold text-center mb-1">
              {activeLeaderboard?.leaderboard.title}
            </h4>
            {!!activeLeaderboard?.end_datetime && (
              <p className="not-italic font-normal text-base text-center">
                This leaderboard resets on{' '}
                {moment(activeLeaderboard?.end_datetime).format(
                  'MMM Do'
                )}
              </p>
            )}
          </div>
          <button
            onClick={() =>
              paginateIsInactive('next')
                ? null
                : setActiveBoardIndex((next) =>
                    typeof next === 'number' ? next + 1 : 0
                  )
            }
            disabled={paginateIsInactive('next')}
            className={`btn-next text-xl leading-none mt-1.5 ${
              paginateIsInactive('next')
                ? 'opacity-0 pointer-events-none'
                : 'cursor-pointer'
            }`}
          >
            <FaAngleRight />
          </button>
        </div>

        {!activeLeaderboard?.leaders.length ? (
          <div
            className={`list flex-1 w-full pr-4 flex items-center justify-center`}
          >
            {noResult()}
          </div>
        ) : (
          <div
            className={`list ${
              !!showMyPosition() ? 'has-position' : 'no-position'
            } flex-1 w-full pr-4`}
          >
            {showMyPosition()}

            <ul className="bg-white rounded-2xl w-full px-4 py-5">
              {activeLeaderboard?.leaders.map((leader) => (
                <li key={leader._id}>
                  <div
                    className={`${
                      leader.player === user?._id
                        ? 'active text-white'
                        : 'text-gray-900'
                    } flex items-center justify-between py-3 px-6 rounded-full`}
                  >
                    <p className="position font-bold flex flex-col justify-center items-center">
                      <span>Rank</span>
                      {leader.move === 'up' && <FaAngleUp className="up" />}
                      {leader.position}
                      {leader.move === 'down' && (
                        <FaAngleDown className="down" />
                      )}
                    </p>

                    <div className="user flex items-center justify-start flex-1">
                      <span
                        className={`img ${
                          !!leader.image ? 'has-image' : 'no-image'
                        } w-9 h-9 flex items-center justify-center rounded-full overflow-hidden border border-white border-solid mr-4 text-gray-900`}
                      >
                        {!!leader.image ? (
                          <>
                            <img
                              src={leader.image}
                              alt={leader.name}
                              className="h-full w-full block object-cover"
                            />
                          </>
                        ) : (
                          <>
                            <BiUser className="w-full h-full bg-gray-100" />
                          </>
                        )}
                      </span>
                      <p>{leader.name}</p>
                    </div>

                    <p className="rate flex flex-col justify-center items-center">
                      <span>Rate</span>
                      {leader.total}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  if (!showModal) {
    return null;
  }

  return (
    <div
      id="modal"
      className={`leaderboards fixed flex items-center justify-center top-0 left-0 z-90 w-full h-full p-10 ${
        closing ? 'fadeOut' : ''
      }`}
    >
      <span
        className="absolute top-0 left-0 z-10 bg-black bg-opacity-70 w-full h-full"
        onClick={() => close()}
      ></span>
      <div className="content relative z-20 rounded-xl">
        <button className="bt-close absolute" onClick={() => close()}>
          <AiOutlineCloseCircle />
        </button>
        <div className="title absolute flex items-center justify-center">
          <img
            src={IconLeaderboard}
            alt="Leaderboards"
            className="ico absolute pointer-events-none"
          />
          <h3>Leaderboards</h3>
        </div>

        <div className="px-6 pb-6 pt-24 w-full flex justify-center items-center h-full overflow-hidden">
          {loading ? renderLoading() : renderList()}
        </div>
      </div>
    </div>
  );
});

export default LeaderboardsModal;
