import "./style.scss";

type HexBoxParams = {
  children?: React.ReactNode;
};

export default function HexBox(props: HexBoxParams) {
  const { children } = props;

  return <div className="bg-white py-3 hex-box">{children}</div>;
}
