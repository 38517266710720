/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import moment from 'moment';

import Logo from 'assets/img/logo.svg';
import HexTitle from 'assets/img/hex-title.svg';

import { AuthContext } from 'context/AuthContext';
// import Robot from 'components/Robot';
import { useState } from 'react';
import {
  getAverageStats,
  getCurrentStats,
  getDailyStats,
  getLastAction,
  // getGlobalStats,
  // getLifetimeStats,
  getPersonalBestStats,
  getRollingStats,
  // getTotalUsers,
  getWeeklyStats,
} from 'services/dashboard.service';
import Hex from 'components/Hex';
import Heading from 'components/Heading';
import DailyCard from 'components/DailyCard';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import '../styles.scss';
import { formatDistance } from 'date-fns';
import DailyCardLoader from 'components/DailyCard/loader';
import { Call } from 'types/Call';
import { getHexStatus } from 'utils/hex';
import UserInfo from 'components/UserInfo';
import UserAdvantages from 'components/UserAdvantages';
import { DashboardFilter } from 'components/DashboardFilter';
import { getStorageItem, setStorageItem } from 'utils/storage';

const fields = ['mx_credit_payments', 'mx_call', 'mx_order', 'mx_flex_offer'];
const leaderboardsIDs = ['mx'];
// const leaderboardsIDs = ['mx'];
const timeZone = -4;
const defaultStartDate = moment().startOf('day').subtract(14, 'days').toDate();
const defaultEndDate = moment().startOf('day').toDate();

export function DashboardMX() {
  const { user, loadingUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [counterLoadedData, setCounterLoadedData] = useState<number>(0);

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);

  // const [totalUsers, setTotalUsers] = useState<any>();
  // const [lifeTimeCalls, setLifeTimeCalls] = useState<any>();
  // const [globalCalls, setGlobalCalls] = useState<any>();

  const [currentCalls, setCurrentCalls] = useState<any[]>();
  const [dailyCalls, setDailyCalls] = useState<any[]>();
  const [weeklyCalls, setWeeklyCalls] = useState<any[]>();
  const [personalBest, setPersonalBest] = useState<any>();
  const [averageStats, setAverageStats] = useState<any>();
  const [lastUpdate, setLastUpdate] = useState<any>();

  const [rollingStats, setRollingStats] = useState<any>();
  const [closingOffer, setClosingOffer] = useState<any>({
    us_consumer: 0,
    business: 0,
    pr: 0,
    mx: 0,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionalServices, setOptionalServices] = useState<any>();

  const actualDates = useMemo(
    () => ({
      defaultStartDate: defaultStartDate,
      defaultEndDate: defaultEndDate,
      startDate: startDate,
      endDate: endDate,
    }),
    [defaultStartDate, defaultEndDate, startDate, endDate]
  );

  const getDailyOrderKPIs = (key: string) => {
    try {
      const data = currentCalls?.filter((item) => {
        return (
          item.type === key ||
          !isNaN(item[`${key}_call`]) ||
          !isNaN(item[`${key}_order`])
        );
      });

      const dailyData = dailyCalls?.filter((item) => {
        return (
          item.type === key ||
          !isNaN(item[`${key}_call`]) ||
          !isNaN(item[`${key}_order`])
        );
      });

      if (data) {
        return {
          global: [],
          average: averageStats,
          activity: data,
          dailyActivity: dailyData,
          personalBest: personalBest,
        };
      }

      return null;
    } catch (e) {
      return null;
    }
  };

  const prepareClosingOfferKPIs = () => {
    try {
      const weeklyResults: Call = weeklyCalls?.reduce((result, item) => {
        if (!result) {
          result = {};
          fields.forEach((field) => {
            result[field] = item[field];
          });
        } else {
          fields.forEach((field) => {
            result[field] = result[field] + item[field];
          });
        }

        return result;
      }, null);

      if (weeklyResults) {
        setClosingOffer({
          us_consumer:
            ((weeklyResults.consumer_flex_offer || 0) /
              (weeklyResults.us_consumer_order || 0)) *
              100 || 0,
          business:
            ((weeklyResults.business_flex_offer || 0) /
              (weeklyResults.business_order || 0)) *
              100 || 0,
          pr: 0,
          mx: 0,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const prepareOptionalServicesKPIs = () => {
    try {
      const weeklyResults: Call = weeklyCalls?.reduce((result, item) => {
        if (!result) {
          result = {};
          fields.forEach((field) => {
            result[field] = item[field];
          });
        } else {
          fields.forEach((field) => {
            result[field] = result[field] + item[field];
          });
        }

        return result;
      }, null);

      if (weeklyResults) {
        setOptionalServices({
          consumer_voice_attachment:
            ((weeklyResults.consumer_voice_domestic_2yr_commit +
              weeklyResults.consumer_voice_domestic_no_commit) /
              weeklyResults.us_consumer_order) *
              100 || 0,
          consumer_er_attachment:
            ((weeklyResults.consumer_er_basic +
              weeklyResults.consumer_er_premium) /
              weeklyResults.us_consumer_order) *
              100 || 0,
          consumer_norton_attachment:
            (weeklyResults.consumer_norton / weeklyResults.us_consumer_order) *
              100 || 0,
          business_voice_attachment:
            (weeklyResults.business_voice_domestic_2yr_commit /
              weeklyResults.us_consumer_order) *
              100 || 0,
          business_er_attachment:
            ((weeklyResults.business_er_basic +
              weeklyResults.business_er_premium) /
              weeklyResults.business_order) *
              100 || 0,
          business_norton_attachment:
            (weeklyResults.business_norton / weeklyResults.business_order) *
              100 || 0,
          pr_voice_attachment:
            ((weeklyResults.consumer_voice_domestic_2yr_commit +
              weeklyResults.consumer_voice_domestic_no_commit) /
              weeklyResults.pr_order) *
              100 || 0,
          pr_er_attachment:
            ((weeklyResults.consumer_er_basic +
              weeklyResults.consumer_er_premium) /
              weeklyResults.pr_order) *
              100 || 0,
          pr_norton_attachment:
            (weeklyResults.consumer_norton / weeklyResults.pr_order) * 100 || 0,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeDate = useCallback((_startDate: Date, _endDate: Date) => {
    setStartDate(_startDate);
    setEndDate(_endDate);
  }, []);

  const loadData = useCallback(async () => {
    try {
      if (!user || !!loadingUserData) return;

      setLoading(true);

      const _startDate = moment(
        `${moment(startDate).format('YYYY')}-${moment(startDate).format(
          'MM'
        )}-${moment(startDate).format('DD')}T00:00:00-00:00`
      )
        .utcOffset(timeZone)
        .valueOf();
      const _endDate = moment(
        `${moment(endDate).format('YYYY')}-${moment(endDate).format(
          'MM'
        )}-${moment(endDate).format('DD')}T23:59:59-00:00`
      )
        .utcOffset(timeZone)
        .valueOf();

      // const { total } = await getTotalUsers();
      // const reqGlobalCalls = await getGlobalStats();
      // const reqLifeTimeStats = await getLifetimeStats(user?._id);

      const reqCurrentCalls = await getCurrentStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqWeekyCalls = await getWeeklyStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqDailyCalls = await getDailyStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqPersonalBest = await getPersonalBestStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqAverageStats = await getAverageStats(_startDate, _endDate);
      const reqRollingStats = await getRollingStats(user?._id);
      const reqLastAction = await getLastAction(user?._id);

      // setTotalUsers(total);
      // setGlobalCalls(reqGlobalCalls);
      // setLifeTimeCalls(reqLifeTimeStats);

      setCurrentCalls(reqCurrentCalls);
      setWeeklyCalls(reqWeekyCalls);
      setDailyCalls(reqDailyCalls);
      setPersonalBest(reqPersonalBest);
      setAverageStats(reqAverageStats);
      setRollingStats(reqRollingStats);
      setLastUpdate(reqLastAction);

      if (reqCurrentCalls?.length) {
        const currentCalls = Object.keys(reqCurrentCalls[0])
          .filter((key) => !!key.match(/_order/g))
          .map((key) => ({
            product: key.replace(/(.*)(_order)/g, '$1'),
            total: reqCurrentCalls[0][key],
          }));
        const total = currentCalls
          .map((obj: { product: string; total: number }) => obj.total)
          .reduce((acc, num) => (acc += num));

        const storedCurrentCalls = getStorageItem('current_calls');

        setStorageItem('current_calls', {
          current_calls: currentCalls,
          total,
          first_sale:
            storedCurrentCalls && storedCurrentCalls.total < 1 && total === 1,
        });
      } else {
        setStorageItem('current_calls', {
          current_calls: [],
          total: 0,
          first_sale: false,
        });
      }

      setLoading(false);
      setCounterLoadedData((prevCounter) => prevCounter + 1);
    } catch (e) {}
  }, [startDate, endDate]);

  useEffect(() => {
    if (counterLoadedData === 1) {
      document.dispatchEvent(new CustomEvent('loadNotifications'));
    }
  }, [counterLoadedData]);

  useEffect(() => {
    if (!!user) {
      loadData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!user) return;

    const autoRefresh = setInterval(() => {
      loadData();
      document.dispatchEvent(new CustomEvent('loadNotifications'));
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(autoRefresh);
    };
  }, [user]);

  useEffect(() => {
    if (!!weeklyCalls) {
      prepareClosingOfferKPIs();
      prepareOptionalServicesKPIs();
    }
  }, [weeklyCalls]);

  return (
    <>
      <div className="absolute mx-auto w-80 top-10 left-0 right-0">
        <img src={Logo} alt="" className="w-full" />
        <div className="mt-2">
          <div className="relative">
            <div className="absolute w-full h-full flex items-center justify-center text-white font-bold">
              {!!loading && <span>Loading...</span>}
              {!loading && lastUpdate && (
                <span>
                  Updated{' '}
                  {formatDistance(lastUpdate?.time, new Date(), {
                    addSuffix: true,
                  })}
                </span>
              )}
              {!loading && !lastUpdate && <span>Waiting for updates</span>}
            </div>
            <img src={HexTitle} alt="" className="w-full pointer-events-none" />
          </div>
        </div>
      </div>

      <div id="dashboard-mx" className="flex flex-col w-full h-screen relative">
        {/* HEADER */}
        <div className="flex justify-between items-start pt-10 px-10">
          {/* USER */}
          <div id="user-data" className="relative flex items-start">
            <UserInfo />
            <UserAdvantages leaderboardsIDs={leaderboardsIDs} />
          </div>

          {/* ROLLING STATS */}
          <div id="rolling-stats" className="flex space-x-1">
            {/* <UpDown
              label="Mexico"
              up={{
                label: 'Paid Order',
                value: rollingStats?.attributes.mx_paid_orders,
                status: getHexStatus(rollingStats?.attributes.mx_paid_orders, 'mx_paid_orders'),
              }}
              down={{
                label: 'Activations',
                value: rollingStats?.attributes.mx_activations,
                status: getHexStatus(rollingStats?.attributes.mx_activations, 'mx_activations'),
              }}
            /> */}

            <Hex
              type="wide"
              value={rollingStats?.attributes.mx_paid_orders}
              status={getHexStatus(
                rollingStats?.attributes.mx_paid_orders,
                'mx_paid_orders'
              )}
              label="Paid Order"
            />

            <Hex
              type="wide"
              value={rollingStats?.attributes.mx_activations}
              status={getHexStatus(
                rollingStats?.attributes.mx_activations,
                'mx_activations'
              )}
              label="Activations"
            />
          </div>
        </div>

        <div>
          <div className="w-full flex flex-col items-center mt-2">
            <div className="text-white font-bold">
              Displaying data for period:
            </div>
            <DashboardFilter
              onChangeDate={handleChangeDate}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
            />
          </div>

          <div className="flex flex-1 justify-between pt-6">
            {/* CLOSING OFFER */}
            <div className="flex flex-1 flex-col items-start">
              <Heading>
                <div className="text-right leading-tight">
                  CLOSING OFFER APPLIED
                  {/* <div className="text-sm font-normal">Current week</div> */}
                </div>
              </Heading>
              <div
                id="closing-offer"
                className="w-full flex-1 flex items-center justify-center"
              >
                <Hex
                  type="vertical"
                  value={closingOffer?.mx}
                  suffix="%"
                  status={getHexStatus(closingOffer?.mx, 'mx')}
                  label="Mexico"
                />
              </div>
            </div>

            {/* CENTER */}
            <div className="flex flex-1 flex-col items-start">
              <Heading>DAILY ORDER CLOSE RATES</Heading>

              <div className="w-full flex-1 flex pt-8 2xl:pt-auto 2xl:items-center justify-center">
                {!weeklyCalls && <DailyCardLoader items={1} />}
                {weeklyCalls && (
                  <div id="daily-order">
                    <DailyCard
                      label="Mexico"
                      type={'mx'}
                      data={getDailyOrderKPIs('mx')}
                      actualDates={actualDates}
                      responsive
                    />
                  </div>
                )}
              </div>
            </div>

            {/* OPTIONAL SERVICES */}
            <div className="flex flex-1 flex-col items-start">
              <Heading>
                <div className="text-right leading-tight">
                  OPTIONAL SERVICES
                  {/* <div className="text-sm font-normal">Current week</div> */}
                </div>
              </Heading>
              <div
                id="optional-services"
                className="w-full flex-1 flex flex-row items-center justify-center"
              >
                <Hex
                  type="vertical"
                  value={rollingStats?.attributes.mx_credit_payments}
                  status={getHexStatus(
                    rollingStats?.attributes.mx_credit_payments,
                    'mx_credit_payments'
                  )}
                  label="Mexico<br/>Credit Card<br/>Capture"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <Robot /> */}
      </div>
    </>
  );
}
