export default function HexUp({ color = '#88D824' }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 178 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_10_274)">
        <path
          d="M93.7572 198.427C90.7889 200.033 87.2111 200.033 84.2428 198.427L9.24282 157.865C6.01273 156.118 3.99999 152.741 3.99999 149.069L4 50.9313C4 47.2591 6.01274 43.8823 9.24283 42.1353L84.2428 1.57284C87.2111 -0.0325083 90.7889 -0.0325081 93.7572 1.57284L168.757 42.1354C171.987 43.8823 174 47.2591 174 50.9314L174 149.069C174 152.741 171.987 156.118 168.757 157.865L93.7572 198.427Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_10_274"
          x="0"
          y="0.368835"
          width="178"
          height="207.262"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_274" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_274" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
