import { useRef } from 'react';
import Leaderboards from 'components/LeaderboardsModal';
// import Challenges from 'components/ChallengesModal';
// import Rewards from 'components/RewardsModal';

// import IconChallenges from 'assets/img/icon-challenges.png';
// import IconRewards from 'assets/img/icon-rewards.png';
import IconLeaderboard from 'assets/img/icon-leaderboard.png';

export default function UserAdvantages({
  leaderboardsIDs,
}: {
  leaderboardsIDs: string[];
}) {
  const leaderboardsRef = useRef<any>();
  const challengesRef = useRef<any>();
  const rewardsRef = useRef<any>();

  const onOpenAdvantages = (name: string) => {
    switch (name) {
      case 'leaderboards':
        leaderboardsRef.current?.setLeaderboardsIDs(leaderboardsIDs);
        leaderboardsRef.current?.open();
        break;
      case 'challenges':
        challengesRef.current?.open();
        break;
      case 'rewards':
        rewardsRef.current?.open();
        break;
    }
  };

  return (
    <>
      <div className="user-advantages relative pt-3">
        <button
          className="mx-1.5"
          onClick={() => onOpenAdvantages('leaderboards')}
        >
          <span>
            <img src={IconLeaderboard} alt="Leaderboards" />
          </span>
          <strong>Leaderboard</strong>
        </button>
        {/* <button
          className="mx-1.5 opacity-50 cursor-default"
          // onClick={() => onOpenAdvantages('challenges')}
        >
          <span>
            <img src={IconChallenges} alt="Challenges" />
          </span>
          <strong>Challenges (Soon)</strong>
        </button> */}
        {/* <button
          className="mx-1.5 opacity-50 cursor-default"
          // onClick={() => onOpenAdvantages('rewards')}
        >
          <span>
            <img src={IconRewards} alt="Rewards" />
          </span>
          <strong>Rewards (Soon)</strong>
        </button> */}
      </div>

      <Leaderboards ref={leaderboardsRef} />
      {/* <Challenges ref={challengesRef} /> */}
      {/* <Rewards ref={rewardsRef} /> */}
    </>
  );
}
