import { useMemo } from 'react';
import HexDown from './shapes/hex-down';
import HexHorizontal from './shapes/hex-horizontal';
import HexUp from './shapes/hex-up';
import HexVertical from './shapes/hex-vertical';
import HexWide from './shapes/hex-wide';

export type HexType = 'up' | 'down' | 'horizontal' | 'vertical' | 'wide';
export type HexStatusType = 'high' | 'medium' | 'low';
export const HexStatusColor = {
  high : '#88D824',
  medium : '#FF9B05',
  low : '#DF3B17 ',
};

type HexParams = {
  type: HexType;
  label?: string;
  value: number;
  prefix?: string;
  suffix?: string;
  status?: HexStatusType;
};

export default function Hex(props: HexParams) {
  const { type, label = '', value = 0, prefix = '', suffix = '', status = 'medium' } = props;

  const alignment = useMemo(() => {
    switch (type) {
      case 'up':
        return 'justify-center';
      case 'down':
        return 'justify-center pb-6';
      default:
        return 'justify-center';
    }
  }, [type]);

  const titleSize = useMemo(() => {
    switch (type) {
      case 'up':
      case 'down':
        return 'text-3xl';
      default:
        return 'text-3xl 2xl:text-4xl';
    }
  }, [type]);

  const labelSize = useMemo(() => {
    switch (type) {
      case 'up':
      case 'down':
        return 'text-sm';
      case 'vertical':
        return 'text-md';
      default:
        return 'text-xs 2xl:text-xs';
    }
  }, [type]);

  return (
    <div className="hex relative w-full">
      <div className={`absolute w-full h-full flex flex-col items-center text-center ${alignment}`}>
        <h1
          className={`font-bold text-white text-shadow mb-1 ${titleSize}`}
          dangerouslySetInnerHTML={{ __html: `${prefix}${isFinite(value) ? value.toFixed(0) : 0}${suffix}` || '' }}
        ></h1>
        <p className={`${labelSize} ${status === 'low' ? 'text-white font-bold text-shadow' : ''} leading-none 2xl:leading-none`} dangerouslySetInnerHTML={{ __html: label || '' }}></p>
      </div>
      {type === 'up' && <HexUp color={HexStatusColor[status]} />}
      {type === 'down' && <HexDown color={HexStatusColor[status]} />}
      {type === 'horizontal' && <HexHorizontal color={HexStatusColor[status]} />}
      {type === 'vertical' && <HexVertical color={HexStatusColor[status]} />}
      {type === 'wide' && <HexWide color={HexStatusColor[status]} />}
    </div>
  );
}
