export default function HexUp({ color = '#FF9B05' }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 170 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7_672)">
        <path
          d="M164.131 67.3172C165.903 70.4018 165.903 74.1957 164.131 77.2804L128.878 138.641C127.094 141.746 123.787 143.66 120.207 143.66L49.7859 143.66C46.2057 143.66 42.8986 141.746 41.1151 138.641L5.86201 77.2804C4.08984 74.1957 4.08982 70.4018 5.86201 67.3172L41.1151 5.95635C42.8986 2.85197 46.2057 0.93793 49.7859 0.93793L120.207 0.937933C123.787 0.937933 127.094 2.85197 128.878 5.95635L164.131 67.3172Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7_672"
          x="0.532875"
          y="0.937927"
          width="168.927"
          height="150.722"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_672" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_672" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
