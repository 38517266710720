import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from './context/AuthContext';
import { BasicProvider } from 'context/BasicContext';
import { RouteList } from './routes';

import './App.scss';
import { NotificationProvider } from 'context/NotificationContext';

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <NotificationProvider>
        <BasicProvider>
          <RouteList />
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </BasicProvider>
      </NotificationProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
