export default function HexUp({ color = '#FF9B05' }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 110 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7_668)">
        <path
          d="M59.9466 89.6629C56.8525 91.4493 53.0406 91.4493 49.9466 89.6629L6.99999 64.8677C3.90598 63.0814 1.99999 59.7801 1.99999 56.2075L1.99999 10.7367C1.99999 5.25577 6.41196 0.796164 11.8926 0.737289L54.9466 0.274844L98.0005 0.737296C103.481 0.796173 107.893 5.25578 107.893 10.7367L107.893 56.2075C107.893 59.7801 105.987 63.0814 102.893 64.8677L59.9466 89.6629Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7_668"
          x="0"
          y="0.274841"
          width="109.893"
          height="94.7278"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_668" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_668" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
