import Hex, { HexStatusType } from 'components/Hex';
import './styles.scss';

type UpDownType = {
  label: string;
  up?: {
    value: number;
    label: string;
    status?: HexStatusType;
  };
  down?: {
    value: number;
    label: string;
    status?: HexStatusType;
  };
};

export default function UpDown(props: UpDownType) {
  const { label, up, down } = props;

  let up_status: HexStatusType = 'medium';
  let down_status: HexStatusType = 'medium';

  if (up?.status) {
    up_status = up.status;
  } else {
    if (up && up.value > 5) {
      up_status = 'low';
    } else if (up && up.value === 0) {
      up_status = 'high';
    }
  }

  if (down?.status) {
    down_status = down.status;
  } else {
    if (down && down.value < 5) {
      down_status = 'low';
    } else if (down && down.value > 10) {
      down_status = 'high';
    }
  }

  return (
    <div className='updown'>
      <div>
        <Hex type="up" value={up?.value || 0} label={up?.label} status={up_status} />
      </div>
      <div className="text-center text-white font-bold text-sm pb-1">{label}</div>
      <div>
        <Hex type="down" value={down?.value || 0} label={down?.label} status={down_status} />
      </div>
    </div>
  );
}
