import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  Legend,
  Tooltip,
  ChartOptions,
  ChartData,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { add, startOfWeek } from 'date-fns';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, LineController, BarController, Tooltip, Legend);

const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const options: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `${tooltipItem.dataset.label} - ${tooltipItem.formattedValue}%`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      display: false,
    },
    x: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return this.getLabelForValue(index).substring(0, 1);
        },
      },
    },
  },
};

export function DailyChart(props: any) {
  const [data, setData] = useState<ChartData>();

  useEffect(() => {
    const { personal, average, activity } = props;

    // console.log('DailyChart', personal, average, activity);

    const personalBestData = [personal, personal, personal, personal, personal, personal, personal];
    const averageData = [average, average, average, average, average, average, average];

    const lastWeekData = [0, 0, 0, 0, 0, 0, 0];
    const currentWeekData = [0, 0, 0, 0, 0, 0, 0];

    const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

    const timezone = new Date().getTimezoneOffset();
    
    activity.forEach((item: any) => {
      const activityDate = add(new Date(item.date),{minutes:timezone});
      let weekDay = activityDate.getDay();
      if (item.date < firstDayOfWeek) {
        if(weekDay === 0) weekDay = 7;
        lastWeekData[weekDay - 1] = item.performance.toFixed(0);
      } else {
        currentWeekData[weekDay - 1] = item.performance.toFixed(0);
      }
    });

    const chartData = {
      labels,
      datasets: [
        {
          type: 'line' as const,
          label: 'Personal Best',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: personalBestData,
          pointBorderColor: 'rgba(0, 0, 0, 0)',
        },
        {
          type: 'line' as const,
          label: 'Average',
          borderColor: 'rgb(185, 0, 232)',
          borderWidth: 2,
          fill: false,
          data: averageData,
          pointBorderColor: 'rgba(0, 0, 0, 0)',
        },
        {
          type: 'bar' as const,
          label: 'Last Week',
          backgroundColor: '#ffcc00',
          data: lastWeekData,
          borderColor: 'white',
          borderWidth: 2,
        },
        {
          type: 'bar' as const,
          label: 'Current Week',
          backgroundColor: 'rgb(75, 192, 292)',
          data: currentWeekData,
          borderColor: 'white',
          borderWidth: 2,
        },
      ],
    };

    setData(chartData);
  }, [props]);

  if (!data) return null;

  return <Chart id="" type="bar" data={data} options={options} />;
}
