import { useContext, createContext, useState, useLayoutEffect, ReactNode, useEffect } from 'react';
import { getPointsCategories, PointsCategoriesInterface } from 'services/points.service';
import { AuthContext } from 'context/AuthContext';

interface ChildrenProp {
  children: ReactNode;
}

export interface BasicContextInterface {
  width: number;
  height: number;
  pointsCategories: PointsCategoriesInterface[] | undefined;
}

const BasicContext = createContext({} as BasicContextInterface);

function BasicProviderData(): BasicContextInterface {
  const { user } = useContext(AuthContext);

  const [pointsCategories, setPointsCategories] = useState<PointsCategoriesInterface[] | undefined>();
  const [width, height] = useWindowSize();

  useEffect(() => {
    if (user?._id) getPointsCategories().then(data => setPointsCategories(data));
  }, [user]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
  }

  return {
    width,
    height,
    pointsCategories,
  };
}

const BasicProvider = ({ children }: ChildrenProp) => {
  const contextData: BasicContextInterface = BasicProviderData();
  return <BasicContext.Provider value={contextData}>{children}</BasicContext.Provider>;
};

const useBasic = (): BasicContextInterface => {
  const context = useContext(BasicContext);

  if (typeof context === 'undefined') {
    throw new Error('useBasic must be used within an BasicProvider');
  }

  return context;
};

export { BasicProvider, useBasic };
