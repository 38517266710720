import ContentLoader from 'react-content-loader';

type DailyCardLoaderProps = {
  items?: number;
};

const DailyCardLoader = (props: DailyCardLoaderProps) => {
  function renderItems() {
    const items = [];
    const totalItems = props.items || 3;
    for (let i = 0; i < totalItems; i++) {
      items.push(i);
    }
    return (
      <>
        {items.map((item,index) => (
          <div key={index} className="daily-card bg-white p-4 rounded-xl">
            <ContentLoader
              speed={2}
              width={190}
              height={250}
              viewBox="0 0 240 250"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
              <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              <circle cx="20" cy="20" r="20" />
            </ContentLoader>
          </div>
        ))}
      </>
    );
  }

  return <div className="flex px-10 pb-10 space-x-4">{renderItems()}</div>;
};

export default DailyCardLoader;
