/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper';

import Logo from 'assets/img/logo.svg';
import HexTitle from 'assets/img/hex-title.svg';

import { AuthContext } from 'context/AuthContext';
import UpDown from 'components/UpDown';
// import Robot from 'components/Robot';
import { useState } from 'react';
import {
  getAverageStats,
  getCurrentStats,
  getDailyStats,
  getLastAction,
  // getGlobalStats,
  // getLifetimeStats,
  getPersonalBestStats,
  getRollingStats,
  // getTotalUsers,
  getWeeklyStats,
} from 'services/dashboard.service';
import Hex from 'components/Hex';
import Heading from 'components/Heading';
import DailyCard from 'components/DailyCard';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import '../styles.scss';
import { formatDistance } from 'date-fns';
import DailyCardLoader from 'components/DailyCard/loader';
import { Call } from 'types/Call';
import { getHexStatus } from 'utils/hex';
import UserInfo from 'components/UserInfo';
import UserAdvantages from 'components/UserAdvantages';
import { DashboardFilter } from 'components/DashboardFilter';
import { getStorageItem, setStorageItem } from 'utils/storage';

const fields = [
  'us_consumer_call',
  'us_consumer_order',
  'dealer_call',
  'dealer_order',
  'referral_call',
  'referral_order',
  'business_call',
  'business_order',
  'consumer_spanish_call',
  'consumer_spanish_order',
  'pr_call',
  'pr_order',
  'mx_call',
  'mx_order',
  'consumer_er_basic',
  'consumer_er_premium',
  'us_consumer_er_basic',
  'us_consumer_er_premium',
  'consumer_flex_offer',
  'us_consumer_flex_offer',
  'consumer_norton',
  'us_consumer_norton',
  'business_er_basic',
  'business_er_premium',
  'business_flex_offer',
  'business_norton',
  'consumer_voice_domestic_2yr_commit',
  'consumer_voice_domestic_no_commit',
  'business_voice_domestic_2yr_commit',
];
const leaderboardsIDs = ['us_consumer', 'norton_attachment', 'er_attachment'];
// const leaderboardsIDs = ['us_consumer', 'norton_attachment', 'er_attachment', 'dealer', 'referral', 'business'];
const timeZone = -4;
const defaultStartDate = moment().startOf('day').subtract(14, 'days').toDate();
const defaultEndDate = moment().startOf('day').toDate();

export function DashboardUS() {
  const { user, loadingUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [counterLoadedData, setCounterLoadedData] = useState<number>(0);

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);

  // const [totalUsers, setTotalUsers] = useState<any>();
  // const [lifeTimeCalls, setLifeTimeCalls] = useState<any>();
  // const [globalCalls, setGlobalCalls] = useState<any>();

  const [currentCalls, setCurrentCalls] = useState<any[]>();
  const [dailyCalls, setDailyCalls] = useState<any[]>();
  const [weeklyCalls, setWeeklyCalls] = useState<any[]>();
  const [personalBest, setPersonalBest] = useState<any>();
  const [averageStats, setAverageStats] = useState<any>();
  const [lastUpdate, setLastUpdate] = useState<any>();

  const [rollingStats, setRollingStats] = useState<any>();
  const [closingOffer, setClosingOffer] = useState<any>({
    us_consumer: 0,
    business: 0,
    pr: 0,
    mx: 0,
  });
  const [optionalServices, setOptionalServices] = useState<any>();

  const actualDates = useMemo(
    () => ({
      defaultStartDate: defaultStartDate,
      defaultEndDate: defaultEndDate,
      startDate: startDate,
      endDate: endDate,
    }),
    [defaultStartDate, defaultEndDate, startDate, endDate]
  );

  const getDailyOrderKPIs = (key: string) => {
    try {
      const data = currentCalls?.filter((item) => {
        return (
          item.type === key ||
          !isNaN(item[`${key}_call`]) ||
          !isNaN(item[`${key}_order`])
        );
      });

      const dailyData = dailyCalls?.filter((item) => {
        return (
          item.type === key ||
          !isNaN(item[`${key}_call`]) ||
          !isNaN(item[`${key}_order`])
        );
      });

      if (data) {
        return {
          global: [],
          average: averageStats,
          activity: data,
          dailyActivity: dailyData,
          personalBest: personalBest,
        };
      }

      return null;
    } catch (e) {
      return null;
    }
  };

  const prepareClosingOfferKPIs = () => {
    try {
      const weeklyResults: Call = weeklyCalls?.reduce((result, item) => {
        if (!result) {
          result = {};
          fields.forEach((field) => {
            result[field] = item[field];
          });
        } else {
          fields.forEach((field) => {
            result[field] = result[field] + item[field];
          });
        }

        return result;
      }, null);

      if (weeklyResults) {
        setClosingOffer({
          us_consumer:
            ((weeklyResults.us_consumer_flex_offer +
              weeklyResults.consumer_flex_offer || 0) /
              (weeklyResults.us_consumer_order || 0)) *
              100 || 0,
          business:
            ((weeklyResults.business_flex_offer || 0) /
              (weeklyResults.business_order || 0)) *
              100 || 0,
          pr: 0,
          mx: 0,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const prepareOptionalServicesKPIs = () => {
    try {
      const weeklyResults: Call = weeklyCalls?.reduce((result, item) => {
        if (!result) {
          result = {};
          fields.forEach((field) => {
            result[field] = item[field];
          });
        } else {
          fields.forEach((field) => {
            result[field] = result[field] + item[field];
          });
        }

        return result;
      }, null);

      // console.log(weeklyResults);

      if (weeklyResults) {
        setOptionalServices({
          consumer_voice_attachment:
            ((weeklyResults.consumer_voice_domestic_2yr_commit +
              weeklyResults.consumer_voice_domestic_no_commit) /
              weeklyResults.us_consumer_order) *
              100 || 0,
          consumer_er_attachment:
            ((weeklyResults.us_consumer_er_basic +
              weeklyResults.consumer_er_basic +
              weeklyResults.us_consumer_er_premium +
              weeklyResults.consumer_er_premium) /
              weeklyResults.us_consumer_order) *
              100 || 0,
          consumer_norton_attachment:
            ((weeklyResults.us_consumer_norton +
              weeklyResults.consumer_norton) /
              weeklyResults.us_consumer_order) *
              100 || 0,
          business_voice_attachment:
            (weeklyResults.business_voice_domestic_2yr_commit /
              weeklyResults.us_consumer_order) *
              100 || 0,
          business_er_attachment:
            ((weeklyResults.business_er_basic +
              weeklyResults.business_er_premium) /
              weeklyResults.business_order) *
              100 || 0,
          business_norton_attachment:
            (weeklyResults.business_norton / weeklyResults.business_order) *
              100 || 0,
          pr_voice_attachment:
            ((weeklyResults.consumer_voice_domestic_2yr_commit +
              weeklyResults.consumer_voice_domestic_no_commit) /
              weeklyResults.pr_order) *
              100 || 0,
          pr_er_attachment:
            ((weeklyResults.us_consumer_er_basic +
              weeklyResults.consumer_er_basic +
              weeklyResults.us_consumer_er_premium +
              weeklyResults.consumer_er_premium) /
              weeklyResults.pr_order) *
              100 || 0,
          pr_norton_attachment:
            ((weeklyResults.us_consumer_norton +
              weeklyResults.consumer_norton) /
              weeklyResults.pr_order) *
              100 || 0,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeDate = useCallback((_startDate: Date, _endDate: Date) => {
    setStartDate(_startDate);
    setEndDate(_endDate);
  }, []);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      if (!user || !!loadingUserData) return;

      const _startDate = moment(
        `${moment(startDate).format('YYYY')}-${moment(startDate).format(
          'MM'
        )}-${moment(startDate).format('DD')}T00:00:00-00:00`
      )
        .utcOffset(timeZone)
        .valueOf();
      const _endDate = moment(
        `${moment(endDate).format('YYYY')}-${moment(endDate).format(
          'MM'
        )}-${moment(endDate).format('DD')}T23:59:59-00:00`
      )
        .utcOffset(timeZone)
        .valueOf();

      // const { total } = await getTotalUsers();
      // const reqGlobalCalls = await getGlobalStats();
      // const reqLifeTimeStats = await getLifetimeStats(user?._id);

      const reqWeekyCalls = await getWeeklyStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqCurrentCalls = await getCurrentStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqDailyCalls = await getDailyStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqPersonalBest = await getPersonalBestStats(
        user?._id,
        _startDate,
        _endDate
      );
      const reqAverageStats = await getAverageStats(_startDate, _endDate);
      const reqRollingStats = await getRollingStats(user?._id);
      const reqLastAction = await getLastAction(user?._id);

      // setTotalUsers(total);
      // setGlobalCalls(reqGlobalCalls);
      // setLifeTimeCalls(reqLifeTimeStats);

      setCurrentCalls(reqCurrentCalls);
      setDailyCalls(reqDailyCalls);
      setWeeklyCalls(reqWeekyCalls);
      setPersonalBest(reqPersonalBest);
      setAverageStats(reqAverageStats);
      setRollingStats(reqRollingStats);
      setLastUpdate(reqLastAction);

      if (reqCurrentCalls?.length) {
        const currentCalls = Object.keys(reqCurrentCalls[0])
          .filter((key) => !!key.match(/_order/g))
          .map((key) => ({
            product: key.replace(/(.*)(_order)/g, '$1'),
            total: reqCurrentCalls[0][key],
          }));
        const total = currentCalls
          .map((obj: { product: string; total: number }) => obj.total)
          .reduce((acc, num) => (acc += num));

        const storedCurrentCalls = getStorageItem('current_calls');

        setStorageItem('current_calls', {
          current_calls: currentCalls,
          total,
          first_sale:
            storedCurrentCalls && storedCurrentCalls.total < 1 && total === 1,
        });
      } else {
        setStorageItem('current_calls', {
          current_calls: [],
          total: 0,
          first_sale: false,
        });
      }

      setLoading(false);
      setCounterLoadedData((prevCounter) => prevCounter + 1);
    } catch (e) {}
  }, [startDate, endDate]);

  useEffect(() => {
    if (counterLoadedData === 1) {
      document.dispatchEvent(new CustomEvent('loadNotifications'));
    }
  }, [counterLoadedData]);

  useEffect(() => {
    if (!!user) {
      loadData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!user) return;

    const autoRefresh = setInterval(() => {
      loadData();
      document.dispatchEvent(new CustomEvent('loadNotifications'));
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(autoRefresh);
    };
  }, [user]);

  useEffect(() => {
    if (!!weeklyCalls) {
      prepareClosingOfferKPIs();
      prepareOptionalServicesKPIs();
    }
  }, [weeklyCalls]);

  useEffect(() => {
    // console.log(dailyCalls);
  }, [dailyCalls]);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      <div className="absolute mx-auto w-80 top-10 left-0 right-0">
        <img src={Logo} alt="" className="w-full" />
        <div className="mt-2">
          <div className="relative">
            <div className="absolute w-full h-full flex items-center justify-center text-white font-bold">
              {!!loading && <span>Loading...</span>}
              {!loading && lastUpdate && (
                <span>
                  Updated{' '}
                  {formatDistance(lastUpdate?.time, new Date(), {
                    addSuffix: true,
                  })}
                </span>
              )}
              {!loading && !lastUpdate && <span>Waiting for updates</span>}
            </div>
            <img src={HexTitle} alt="" className="w-full pointer-events-none" />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-screen">
        {/* HEADER */}
        <div className="flex justify-between items-start pt-10 px-10">
          {/* USER */}
          <div id="user-data" className="relative flex items-start">
            <UserInfo />
            <UserAdvantages leaderboardsIDs={leaderboardsIDs} />
          </div>

          {/* ROLLING STATS */}
          <div id="rolling-stats" className="flex space-x-1">
            <UpDown
              label="US Consumer"
              up={{
                label: '24 hour cancel',
                value: rollingStats?.attributes.us_consumer_24hr_cancellations,
                status: getHexStatus(
                  rollingStats?.attributes.us_consumer_24hr_cancellations,
                  'consumer_24hr_cancellations'
                ),
              }}
              down={{
                label: 'Activations',
                value: rollingStats?.attributes.us_consumer_activations,
                status: getHexStatus(
                  rollingStats?.attributes.us_consumer_activations,
                  'consumer_activations'
                ),
              }}
            />
            <UpDown
              label="Business"
              up={{
                label: '24 hour cancel',
                value: rollingStats?.attributes.business_24hr_cancellations,
                status: getHexStatus(
                  rollingStats?.attributes.business_24hr_cancellations,
                  'business_24hr_cancellations'
                ),
              }}
              down={{
                label: 'Activations',
                value: rollingStats?.attributes.business_activations,
                status: getHexStatus(
                  rollingStats?.attributes.business_activations,
                  'business_activations'
                ),
              }}
            />
            {/* <UpDown
              label="Puerto Rico"
              up={{ value: rollingStats?.attributes.pr_24hr_cancellations, label: '24 hour cancel' }}
              down={{ value: rollingStats?.attributes.pr_activations, label: 'Activation' }}
            />
            <UpDown
              label="Mexico"
              up={{ value: rollingStats?.attributes.mx_paid_orders, label: 'Paid Order' }}
              down={{ value: rollingStats?.attributes.business_activations, label: 'Activation' }}
            /> */}
          </div>
        </div>

        {/* CENTER */}
        <div className="flex flex-col items-start justify-center relative">
          <div className="absolute -top-16 w-full flex flex-col items-center">
            <div className="text-white font-bold">
              Displaying data for period:
            </div>
            <DashboardFilter
              onChangeDate={handleChangeDate}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
            />
          </div>
          <div className="relative -top-10">
            <Heading>DAILY ORDER CLOSE RATES</Heading>
          </div>
          {!weeklyCalls && (
            <div className="w-full flex justify-center">
              <DailyCardLoader items={3} />
            </div>
          )}
          {weeklyCalls && (
            <div id="daily-order" className="w-full flex justify-center">
              <Swiper
                modules={[FreeMode, Pagination]}
                freeMode
                slidesPerView={'auto'}
                spaceBetween={15}
                pagination={{ clickable: true }}
                style={{ padding: '0 2.8em', margin: '0 auto' }}
              >
                <SwiperSlide>
                  <DailyCard
                    label="US Consumer"
                    type={'us_consumer'}
                    data={getDailyOrderKPIs('us_consumer')}
                    actualDates={actualDates}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DailyCard
                    label="Dealer SOC"
                    type={'dealer'}
                    data={getDailyOrderKPIs('dealer')}
                    actualDates={actualDates}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DailyCard
                    label="Referral"
                    type={'referral'}
                    data={getDailyOrderKPIs('referral')}
                    actualDates={actualDates}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DailyCard
                    label="Business"
                    type={'business'}
                    data={getDailyOrderKPIs('business')}
                    actualDates={actualDates}
                  />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <DailyCard
                    label="US Spanish"
                    type={'consumer_spanish'}
                    data={getDailyOrderKPIs('consumer_spanish')}
                    actualDates={actualDates}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DailyCard label="Puerto Rico" type={'pr'} data={getDailyOrderKPIs('pr')} actualDates={actualDates} />
                </SwiperSlide>
                <SwiperSlide>
                  <DailyCard label="Mexico" type={'mx'} data={getDailyOrderKPIs('mx')} actualDates={actualDates} />
                </SwiperSlide> */}
              </Swiper>
            </div>
          )}
        </div>

        {/* FOOTER */}
        <div className="flex-1 flex justify-between">
          {/* CLOSING OFFER */}
          <div className="flex flex-col items-start">
            <Heading>
              <div className="text-right leading-tight">
                CLOSING OFFER APPLIED
                {/* <div className="text-sm font-normal">Current week</div> */}
              </div>
            </Heading>
            <div id="closing-offer" className="flex-1 flex ml-10 items-center">
              <Hex
                type="vertical"
                value={closingOffer?.us_consumer}
                suffix="%"
                status={getHexStatus(
                  closingOffer?.us_consumer,
                  'closing_offer_us'
                )}
                label="US Consumer"
              />
              <Hex
                type="vertical"
                value={closingOffer?.business}
                suffix="%"
                status={getHexStatus(
                  closingOffer?.business,
                  'closing_offer_business'
                )}
                label="Business"
              />
              {/* <Hex
                type="vertical"
                value={closingOffer?.pr}
                suffix="%"
                status={getHexStatus(closingOffer?.pr)}
                label="Puerto Rico"
              />
              <Hex
                type="vertical"
                value={closingOffer?.mx}
                suffix="%"
                status={getHexStatus(closingOffer?.mx)}
                label="Mexico"
              /> */}
            </div>
          </div>

          {/* OPTIONAL SERVICES */}
          <div className="text-right flex flex-col items-end xl:pb-20 2xl:pb-12">
            <Heading right>
              <div className="text-left leading-tight">
                OPTIONAL SERVICES
                {/* <div className="text-sm font-normal">Current week</div> */}
              </div>
            </Heading>
            <div
              id="optional-services"
              className="flex-1 flex flex-row items-center mr-48 xl:pt-20 2xl:pt-12"
            >
              <Hex
                type="horizontal"
                value={optionalServices?.consumer_voice_attachment}
                status={getHexStatus(
                  optionalServices?.consumer_voice_attachment,
                  'consumer_voice_attachment'
                )}
                suffix="%"
                label="Voice<br/>Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.consumer_er_attachment}
                status={getHexStatus(
                  optionalServices?.consumer_er_attachment,
                  'consumer_er_attachment'
                )}
                suffix="%"
                label="ER<br/>Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.consumer_norton_attachment}
                status={getHexStatus(
                  optionalServices?.consumer_norton_attachment,
                  'consumer_norton_attachment'
                )}
                suffix="%"
                label="Norton<br/>Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.business_voice_attachment}
                status={getHexStatus(
                  optionalServices?.business_voice_attachment,
                  'business_voice_attachment'
                )}
                suffix="%"
                label="Business Voice<br/>Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.business_er_attachment}
                status={getHexStatus(
                  optionalServices?.business_er_attachment,
                  'business_er_attachment'
                )}
                suffix="%"
                label="Business ER<br/>Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.business_norton_attachment}
                status={getHexStatus(
                  optionalServices?.business_norton_attachment,
                  'business_norton_attachment'
                )}
                suffix="%"
                label="Business Norton<br/>Attachment"
              />
              {/* <Hex
                type="horizontal"
                value={optionalServices?.pr_voice_attachment}
                status={getHexStatus(optionalServices?.pr_voice_attachment)}
                suffix="%"
                label="Puerto Rico<br/>Voice<br/>Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.pr_er_attachment}
                status={getHexStatus(optionalServices?.pr_er_attachment)}
                suffix="%"
                label="Puerto Rico<br/>ER Attachment"
              />
              <Hex
                type="horizontal"
                value={optionalServices?.pr_norton_attachment}
                status={getHexStatus(optionalServices?.pr_norton_attachment)}
                suffix="%"
                label="Puerto Rico<br/>Norton<br/>Attachment"
              /> */}
            </div>
          </div>
        </div>

        {/* <Robot /> */}
      </div>
    </>
  );
}
